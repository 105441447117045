import { getAllUrl } from "@/core/js/utils/utils";
import axios from "axios";

const SET_SP_PROPS = "SET_SP_PROPS";
const SPPROPS_URL = "/app/keeper/api/network/splashpage";
const PREFERENCES_URL = "/app/keeper/api/preference/setup";

const guestSetupStore = {
  namespaced: true,
  state: {
    spInfos: {
      companyName: null,
      logoUrl: null,
    },
    spProps: {
      background_picture: "default.png",
      css: {
        logo_size: "50",
        text_font: "Open Sans, Open Sans, sans-serif",
        text_color: { r: 255, g: 255, b: 255 },
        text_accent_color: { r: 91, g: 0, b: 236 },
        container_color: { r: 0, g: 0, b: 0 },
        container_opacity: "50",
        background_type: "COLOR",
        background_color: { r: 200, g: 200, b: 200 },
        oauth_facebook: false,
        fields_selected: [
          {
            key: "firstname",
            name: "prénom",
            optional: false,
            required: true,
            removable: false,
          },
          {
            key: "username",
            name: "adresse email",
            optional: false,
            required: true,
            removable: false,
          },
          {
            key: "password",
            name: "mot de passe",
            optional: false,
            required: true,
            removable: false,
          },
        ],
      },
    },
  },
  mutations: {
    setCompanyName(state, value) {
      state.spInfos.companyName = value;
    },
    setLogoUrl(state, value) {
      state.spInfos.logoUrl = value;
    },
    setTextFont(state, value) {
      state.spProps.css.text_font = value;
    },
    [SET_SP_PROPS](state, value) {
      state.spProps = value;
    },
  },
  actions: {
    createFontLink({ state }) {
      const font = state.spProps.css.text_font.split(",")[0];
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.id = `font-full-${font.toLowerCase().replace(/ /g, "-")}`;
      link.href = `https://fonts.googleapis.com/css?family=${font.replace(
        / /g,
        "+"
      )}:regular`;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    formatDatas({ commit, dispatch }, datas) {
      datas = {
        background_picture: datas.background_picture || null,
        css: {
          ...datas.css,
          logo_size: datas.css.logo_size.toString(),
          container_opacity: datas.css.container_opacity.toString(),
        },
      };
      commit(SET_SP_PROPS, datas);
      dispatch("createFontLink");
    },
    fetchSPProps({ rootState, dispatch }) {
      return axios({
        method: "GET",
        url: getAllUrl(SPPROPS_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
        },
      })
        .then((response) => {
          if (response.data && response.data.css)
            dispatch("formatDatas", response.data);
        })
        .catch((err) => {
          throw err;
        });
    },
    saveSPProps({ commit }, { picture, css }) {
      commit(SET_SP_PROPS, {
        background_picture: picture,
        css: {
          ...css,
          logo_size: css.logo_size.toString(),
          container_opacity: css.container_opacity.toString(),
        },
      });
    },
    updateSPProps({ rootState }, datas) {
      return axios({
        method: "POST",
        url: getAllUrl(SPPROPS_URL),
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${rootState.userStore.token}`,
        },
        data: datas,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    updatePreferences({ rootState }, datas) {
      return axios({
        method: "PUT",
        url: getAllUrl(PREFERENCES_URL),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
        },
        data: datas,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  getters: {
    spCssProps: (state) => {
      return state.spProps.css;
    },
    spInfosProps: (state) => {
      return state.spInfos;
    },
    spBackgroundPicture: (state) => {
      return state.spProps.background_picture;
    },
  },
};

export default guestSetupStore;
