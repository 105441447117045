import { Bus } from "@/core/js/EventBus";
import { warningMessage } from "@/core/js/utils/msg";
import integrations from "@/shared/integrations";
import store from "@/store";
import SettingsPage from "@/views/settings-page/SettingsPage.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "../views/authentication/AuthLayout.vue";

Vue.use(VueRouter);

const isCorrectIntegrationRoute = (to, from, next) => {
  const integration = integrations.find(
    (integration) => integration.name === to.params.integrationName
  );
  if (integration) next();
  else next({ name: "integrations" });
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["userStore/isAuthenticated"]) {
    next();
  } else {
    store
      .dispatch("userStore/ping")
      .then(
        () => {
          next({ name: "profile" });
        },
        () => {
          next();
        }
      )
      .catch(() => {
        next();
      });
  }
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters["userStore/isAuthenticated"]) {
    next();
  } else {
    next({ name: "login", query: { redirect: to.path } });
  }
};

const isWifi4euAccount = (to, from, next) => {
  if (store.getters["userStore/isAuthenticated"]) {
    if (
      store.getters["userStore/user"] &&
      store.getters["userStore/user"].network.is_wifi4eu
    ) {
      warningMessage("Vous n'avez pas accès à cette page !");
      if (from.path === "/") next({ name: "base" });
    } else {
      next();
    }
  } else {
    next({ name: "login", query: { redirect: to.path } });
  }
};

const kickUser = (to) => {
  if (to.name === "logout") {
    store.dispatch("userStore/logout").then(() => {
      router.push({ name: "login" });
    });
  } else router.push({ name: "login" });
};

function dispatchWaitingPage(to, from, next) {
  if (from.path === "/" || from.path === "/login") {
    next({ name: "profile" });
  } else {
    if (to.meta.notYet) {
      Bus.emitter.emit(Bus.openWaitingPage);
      next(false);
    }
  }
}

const routes = [
  {
    path: "/flow",
    name: "flow",
    beforeEnter: dispatchWaitingPage,
    meta: { primary: "flow", notYet: true },
  },
  {
    path: "/scan-coupon",
    name: "ScanCoupon",
    meta: { primary: "scan-coupon" },
    beforeEnter: ifAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "scan-coupon" */ "@/views/mobile/ScanCoupon/ScanCoupon.vue"
      ),
  },
  {
    path: "/base",
    name: "base",
    redirect: "/base/client",
    component: SettingsPage,
    meta: { primary: "client" },
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: "Clients",
        path: "client",
        meta: { primary: "base", secondary: "client" },
        component: () =>
          import(
            /* webpackChunkName: "base-clients" */ "@/views/base-page/Customers.vue"
          ),
      },
      {
        name: "Campagnes",
        path: "campaigns",
        meta: { primary: "base", secondary: "campaigns" },
        component: () =>
          import(
            /* webpackChunkName: "base-campaigns" */ "@/views/base-page/Campaigns/Campaigns.vue"
          ),
      },
    ],
  },
  {
    path: "/settings",
    redirect: "/settings/profile",
    component: SettingsPage,
    meta: { primary: "profile" },
    beforeEnter: ifAuthenticated,
    children: [
      {
        name: "profile",
        path: "profile",
        meta: { primary: "settings", secondary: "profile" },
        component: () =>
          import(
            /* webpackChunkName: "settings-profile" */ "@/views/settings-page/components/MyProfile.vue"
          ),
      },
      {
        name: "shop",
        path: "shop",
        meta: { primary: "settings", secondary: "shop" },
        component: () =>
          import(
            /* webpackChunkName: "settings-shop" */ "@/views/settings-page/components/Shop/Shop.vue"
          ),
      },
      {
        name: "wifi-settings",
        path: "wifi-settings",
        meta: { primary: "settings", secondary: "wifi-settings" },
        component: () =>
          import(
            /* webpackChunkName: "wifi-settings" */ "@/views/settings-page/components/WifiSettings.vue"
          ),
      },
      {
        name: "security",
        path: "security",
        meta: { primary: "settings", secondary: "security" },
        component: () =>
          import(
            /* webpackChunkName: "settings-security" */ "@/views/settings-page/components/Security.vue"
          ),
      },
      {
        name: "notifications",
        path: "notifications",
        meta: { primary: "settings", secondary: "notifications" },
        component: () =>
          import(
            /* webpackChunkName: "settings-notifications" */ "@/views/settings-page/components/MyNotifications.vue"
          ),
      },
      {
        name: "access-settings",
        path: "access-settings",
        meta: { primary: "settings", secondary: "access-settings" },
        component: () =>
          import(
            /* webpackChunkName: "access-settings" */ "@/views/settings-page/components/AccessSettings.vue"
          ),
      },
      {
        name: "wifi-analytics",
        path: "wifi-analytics",
        meta: { primary: "settings", secondary: "wifi-analytics" },
        component: () =>
          import(
            /* webpackChunkName: "wifi-analytics" */ "@/views/settings-page/components/WiFiAnalytics.vue"
          ),
      },
      {
        name: "qr-code",
        path: "qr-code",
        meta: { primary: "settings", secondary: "qr-code" },
        component: () =>
          import(
            /* webpackChunkName: "qr-code" */ "@/views/settings-page/components/QRCode.vue"
          ),
      },
      {
        name: "tags",
        path: "tags",
        meta: { primary: "settings", secondary: "tags" },
        component: () =>
          import(
            /* webpackChunkName: "tags" */ "@/views/settings-page/components/Tags.vue"
          ),
      },
      {
        name: "billing-overview",
        path: "billing-overview",
        meta: { primary: "settings", secondary: "billing-overview" },
        component: () =>
          import(
            /* webpackChunkName: "billing-overview" */ "@/views/settings-page/components/BillingOverview.vue"
          ),
      },
      {
        name: "billing-invoices",
        path: "billing-invoices",
        meta: { primary: "settings", secondary: "billing-invoices" },
        component: () =>
          import(
            /* webpackChunkName: "billing-invoices" */ "@/views/settings-page/components/BillingInvoices.vue"
          ),
      },
      {
        name: "billing-payment-methods",
        path: "billing-payment-methods",
        meta: { primary: "settings", secondary: "billing-payment-methods" },
        component: () =>
          import(
            /* webpackChunkName: "billing-payment-methods" */ "@/views/settings-page/components/BillingPaymentsMethods.vue"
          ),
      },
      {
        name: "plan",
        path: "plan",
        meta: { primary: "settings", secondary: "plan" },
        component: () =>
          import(
            /* webpackChunkName: "plan" */ "@/views/settings-page/components/Plan.vue"
          ),
      },
      {
        path: "integrations",
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ "@/views/settings-page/components/Integrations/Integrations.vue"
          ),
        children: [
          {
            name: "integrations",
            path: "/",
            meta: { primary: "settings", secondary: "integrations" },
            component: () =>
              import(
                /* webpackChunkName: "integrations-list" */ "@/views/settings-page/components/Integrations/IntegrationsList.vue"
              ),
          },
          {
            name: "integration",
            path: ":integrationName",
            meta: { primary: "settings", secondary: "integration" },
            beforeEnter: isCorrectIntegrationRoute,
            component: () =>
              import(
                /* webpackChunkName: "integration" */ "@/views/settings-page/components/Integrations/Integration.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/guest-setup",
    name: "guest-setup",
    meta: { primary: "guest-setup" },
    redirect: "/guest-setup/branding",
    beforeEnter: isWifi4euAccount,
    component: () =>
      import(
        /* webpackChunkName: "guest-setup" */ "@/views/guest-setup/GuestSetupLayout.vue"
      ),
    children: [
      {
        path: "branding",
        name: "branding",
        meta: { primary: "guest-setup", secondary: "branding" },
        component: () =>
          import(
            /* webpackChunkName: "guest-setup-branding" */ "@/views/guest-setup/Branding.vue"
          ),
      },
      {
        path: "connexion",
        name: "connexion",
        meta: { primary: "guest-setup", secondary: "connexion" },
        component: () =>
          import(
            /* webpackChunkName: "guest-setup-connexion" */ "@/views/guest-setup/Connexion.vue"
          ),
      },
      {
        path: "redirection",
        name: "redirection",
        meta: { primary: "guest-setup", secondary: "redirection" },
        component: () =>
          import(
            /* webpackChunkName: "guest-setup-redirection" */ "@/views/guest-setup/Redirect.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    component: AuthLayout,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "/",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login-page" */ "@/views/authentication/components/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/forgotPassword",
    component: AuthLayout,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "/",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password-page" */ "@/views/authentication/components/ForgotPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: kickUser,
    component: AuthLayout,
  },
  {
    path: "*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
