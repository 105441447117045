import { NotificationProgrammatic as Notification } from "buefy";
import config from "../config/config";
const _ = require("lodash");

const storage = localStorage;
export function set(key, value) {
  return storage.setItem(key, value);
}

export function get(key) {
  return storage.getItem(key);
}

export function remove(key) {
  return storage.removeItem(key);
}
export function capitalize(str) {
  return _.capitalize(str);
}
export function mySort(a, b) {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
}

export function ifElseCondition(ifCond, ifRes, elseRes) {
  if (ifCond) {
    ifRes();
  } else {
    elseRes();
  }
}

export function ifCondition(ifCond, ifRes) {
  if (ifCond) {
    ifRes();
  }
}

export function open(message, type, icon = "") {
  if (icon)
    Notification.open({
      duration: 5000,
      message: message,
      position: "is-top-right",
      type: type,
      hasIcon: true,
      queue: false,
      icon: icon,
    });
  else
    Notification.open({
      duration: 5000,
      message: message,
      position: "is-top-right",
      type: type,
      queue: false,
      hasIcon: true,
    });
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Octets";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

/**
 * Construit une url complète d'accès à l'API
 * Concatène l'url de base et le point d'entrée à l'API.
 * @param {*} url Point d'accès vers API
 */
export function getAllUrl(url) {
  if (!url) {
    open("Une erreur est survenue", "is-danger");
  } else {
    return config.apiBaseUrl + url;
  }
}

/**
 * Calculates the cost of a subscription
 * @param  nodeCount the number of stations installed.
 * @param  plan select the plan in question.
 * @return the subtotal and a price of a subscription.
 */
export function subscription(nodeCount, plan) {
  return plan.price_table.reduce((subtotal, element) => {
    if (nodeCount >= element.from) {
      var effectifCount = Math.min(nodeCount, element.to);
      // Total to the slice that is added to the subtotal :
      subtotal += (effectifCount - element.from + 1) * element.amount;
    }
    return subtotal;
    // Initial value is plan.price
  }, plan.price);
}
